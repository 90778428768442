import { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { UnitConfig } from '@models/surveyData';
import { useAppSelector } from '@reducers/index';
import { FeaturePermissions } from '@services/permissions/FeaturePermissions';
import { getCheckOptions } from '@utils/survey';
import { updateFn } from '../../form/common';
import { NoteInstructionContainer } from '@features/note-instructions/NoteInstructionContainer';
import { SurveyQuestionForm } from '../../survey-configuration/partials/SurveyQuestionForm';
import { SurveyUnitsAndCurrency } from '@features/units-currency-settings';
import { TemplateConfigFormData } from '../types';
import { SurveyDeadlineReminder } from '@features/survey-deadline/SurveyDeadlineReminder';
import { getDate } from '@utils/date';
import { SURVEY } from '@constants/terminology';

interface SurveySettingsProps {
  updateForm: updateFn;
  form: TemplateConfigFormData;
  isCreating: boolean;
  templateId: string;
}

export const TemplateSettings = (props: SurveySettingsProps) => {
  const { updateForm, templateId, form, isCreating } = props;
  const [unitConfig, setUnitConfig] = useState<UnitConfig>();

  const canSetNoteInstructions = useAppSelector(FeaturePermissions.canSetNoteInstructions);
  const canAccessVerification = useAppSelector(FeaturePermissions.canAccessVerification);
  const checkOptions = getCheckOptions(canAccessVerification);

  const handleSetUnitConfig = (unitConfig: UnitConfig) => {
    updateForm({ code: 'unitConfig', value: unitConfig });
    setUnitConfig(unitConfig);
  };

  return (
    <>
      <div className='mt-4'>
        <h5>Template name*</h5>
        <Input
          placeholder={`e.g. Global 2022 CSR ${SURVEY.CAPITALIZED_SINGULAR}`}
          onChange={(el) => updateForm({ code: 'name', value: el.target.value })}
          defaultValue={form.name}
          data-testid='survey-template-name-input'
        />
      </div>
      <SurveyQuestionForm
        disabled={false}
        isCheckboxDisabled={false}
        checkOptions={checkOptions}
        form={form}
        updateForm={updateForm}
      />
      <FormGroup className='mt-4' disabled={false}>
        <SurveyUnitsAndCurrency
          isCreating={isCreating}
          unitConfig={form.unitConfig ?? unitConfig}
          setUnitConfig={handleSetUnitConfig}
          warningText={
            <>
              <b>WARNING:</b> Updating default units will only affect future {SURVEY.PLURAL}.
            </>
          }
        />
        <NoteInstructionContainer
          editorId={templateId}
          canSetNoteInstructions={canSetNoteInstructions}
          updateForm={updateForm}
          noteInstructionsEditorState={form.noteInstructionsEditorState}
          noteInstructions={form.noteInstructions}
        />
        <SurveyDeadlineReminder
          deadlineDate={form.deadlineDate}
          scheduledDates={form.scheduledDates}
          updateForm={updateForm}
        />
      </FormGroup>
    </>
  );
};
