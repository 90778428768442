import axios from 'axios';
import {
  HeadingLevel,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TableLayoutType,
  PageBreak,
  BorderStyle,
  ImageRun,
  Footer,
  PageNumber,
  AlignmentType,
  LineRuleType,
  ShadingType,
  IRunOptions
} from 'docx';
import WWGLogo from '../../images/reports/wwg_logo.png';
import { base64PrefixRegex } from '@utils/image';
import { xmlSanitizer } from '@utils/xml';
import { SafeTextRun } from '@utils/docx';
import { BulletFormatLevel } from './styles';

export const DEFAULTS = {
  INDENT: { left: 750 },
  PARAGRAPH_BEFORE: 120,
  PARAGRAPH_AFTER: 240,
  CELL_PADDING: 40,
  LINE_SPACING: 300,
  TABLE_HEADER_SHADING: {
    fill: 'DDDDDD'
  },
  TABLE_NO_BORDERS: {
    top: {
      style: BorderStyle.SINGLE,
      size: 1,
      color: '444444',
    },
    bottom: {
      style: BorderStyle.SINGLE,
      size: 1,
      color: '444444',
    },
    left: {
      style: BorderStyle.SINGLE,
      size: 0,
      color: 'FFFFFF',
    },
    right: {
      style: BorderStyle.SINGLE,
      size: 0,
      color: 'FFFFFF',
    }
  }
};

const FOOTER_FLOATING = {
  floating: {
    horizontalPosition: {
      offset: 8800000,
    },
    verticalPosition: {
      offset: 6500000,
    },
  },
};

interface StyleBorder {
  style: BorderStyle,
  size: number,
  color: string
}

export interface Styles {
  style?: string,
  indent?: {
    left: number
  };
  heading?: HeadingLevel,
  spacing?: {
    before?: number,
    after?: number,
    line?: number,
    lineRule?: LineRuleType
  },
  borders?: {
    top: StyleBorder,
    bottom: StyleBorder,
    left: StyleBorder,
    right: StyleBorder,
  },
  shading?: {
    fill?: string,
    type?: ShadingType;
    color?: string;
  },
  margins?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  },
  alignment?: AlignmentType,
  textRun?: {
    color?: string;
    size?: number;
    bold?: boolean;
  }
}

export const pagebreak = () => {
  return new Paragraph({
    children: [
      new PageBreak()
    ]
  })
}

export const title = (text: string, styles?: Styles) => {
  return paragraph(text, {
    heading: HeadingLevel.TITLE,
    ...styles
  });
};
export const heading = (text: string, styles?: Styles) => {
  return paragraph(text, {
    heading: HeadingLevel.HEADING_1,
    spacing: {
      before: DEFAULTS.PARAGRAPH_BEFORE,
    },
    ...styles
  });
};

export const heading2 = (text: string, styles?: Styles) => {
  return paragraph(text, {
    heading: HeadingLevel.HEADING_2,
    spacing: {
      before: DEFAULTS.PARAGRAPH_BEFORE,
    },
    ...styles
  });
};

export const heading3 = (text: string, styles?: Styles) => {
  return paragraph(text, {
    heading: HeadingLevel.HEADING_3,
    ...styles
  });
};

export const heading4 = (text: string, styles?: Styles) => {
  return paragraph(text, {
    heading: HeadingLevel.HEADING_4,
    ...styles
  });
};

const baseList = (reference: string, text: string, level: number) => {
  return new Paragraph({
    text: xmlSanitizer(text),
    style: 'plain',
    numbering: {
      reference: reference,
      level,
      custom: true,
    },
    spacing: {
      before: DEFAULTS.PARAGRAPH_BEFORE,
    },
  });
};

export const letterList = (reference: string, text: string) => baseList(reference, text, BulletFormatLevel.LOWER_LETTER);

export const numberList = (reference: string, text: string) => baseList(reference, text, BulletFormatLevel.DECIMAL);

export const bulletList = (text: string, styles?: Styles ) => {
  return new Paragraph({
    children: [
      new SafeTextRun({
        text,
        ...styles?.textRun
      }),
    ],
    bullet: {
      level: 0
    },
    style: 'plain'
  })
}

export const commentBox = (text: string = '') => {
  return new Paragraph({
    text: xmlSanitizer(text),
    style: 'plain',
    border: {
      top: {
        color: 'auto',
        space: 1,
        style: BorderStyle.SINGLE,
        size: 6,
      },
      bottom: {
        color: 'auto',
        space: 1,
        style: BorderStyle.SINGLE,
        size: 6,
      },
      left: {
        color: 'auto',
        space: 1,
        style: BorderStyle.SINGLE,
        size: 6,
      },
      right: {
        color: 'auto',
        space: 1,
        style: BorderStyle.SINGLE,
        size: 6,
      }
    },
    bullet: {
      level: 0
    },
    spacing: {
      before: 240
    },
  })
}

type Cell = Paragraph | Table;
type Row = Cell[];
const createTableCell = (p: Cell, styles: Styles) => new TableCell({
  children: [paragraph(p?.toString(), styles)],
  margins: {
    top: DEFAULTS.CELL_PADDING,
    bottom: DEFAULTS.CELL_PADDING,
    right: DEFAULTS.CELL_PADDING,
    left: DEFAULTS.CELL_PADDING,
  },
  shading: styles.shading
})


export const table = (data: Row[], totalWidth = 9000, SGXHeaderStyle?: any) => {
  const firstRow = data[0];
  const cellCount = firstRow?.length ?? 1;
  const columnWidths = firstRow?.map(() => totalWidth / cellCount) // Evenly distribute cols
  return new Table({
    rows: data.map((row, i) => {
      return new TableRow({
        children: row.map((cell) => {
          const styles: Styles = SGXHeaderStyle && i === 0 ? SGXHeaderStyle : {
            style: i === 0 ? 'bold' : 'plain',
            shading: i === 0 ? DEFAULTS.TABLE_HEADER_SHADING : undefined
          }
          return createTableCell(cell, styles);
        }),
      })
    }),
    columnWidths: columnWidths,
    width: {
      size: totalWidth,
      type: WidthType.DXA,
    },
    layout: TableLayoutType.FIXED,
  })
}

export type ArrayToTableCellType = string | number | Paragraph | Table;

export const arrayToTable = (rows: (ArrayToTableCellType)[][], totalWidth = 9000) => {
  if (!rows[0]) {
    return paragraph('');
  }
  return new Table({
    rows: rows.map(
      (row, i) => arrayToTableRow(row, i === 0 ? { style: 'bold', shading: DEFAULTS.TABLE_HEADER_SHADING } : undefined)
    ),
    columnWidths: rows[0].map(_ => Math.floor(totalWidth / rows[0].length)),
    width: {
      size: totalWidth,
      type: WidthType.DXA,
    },
    layout: TableLayoutType.FIXED
  })
}

export const arrayToTableRow = (row: (ArrayToTableCellType)[], styles?: Styles) => {
  return new TableRow({
    children: row.map(r => cellToTableCell(r, styles))
  });
}

export const cellToTableCell = (cell: (ArrayToTableCellType), styles?: Styles) => {
  return new TableCell({
    children: [
      typeof cell === 'object'
        ?
        cell
        :
        paragraph(String(cell), styles)
    ],
    borders: styles?.borders ?? {},
    shading: styles?.shading ?? {},
    margins: styles?.margins ?? {
      top: 75,
      left: 75,
      right: 75,
      bottom: 75
    }
  })
}

export const paragraphWithBoldText = (textBold: string, text: string, style: string = 'plain') => {
  return new Paragraph({
    children: [
      new SafeTextRun({
        text: textBold,
        bold: true,
        style: 'bold'
      }),
      new SafeTextRun(text)
    ],
    style: style,
    spacing: {
      before: DEFAULTS.PARAGRAPH_BEFORE,
      after: DEFAULTS.PARAGRAPH_AFTER,
    },
  })
};

export const paragraphWithMultiBoldText = (texts: IRunOptions[]) => {
  return new Paragraph({
    children: texts.map(
        (item) =>
          new SafeTextRun({
            text: item.text,
            bold: item.style === 'bold',
            style: item.style ?? '',
          })
      ),
    spacing: {
      before: DEFAULTS.PARAGRAPH_BEFORE,
      after: DEFAULTS.PARAGRAPH_AFTER,
    },
  });
};

export const paragraphArray = (texts: string[], styles?: Styles) => {
  return new Paragraph({
    children: texts.map((t, i) => {
      return new SafeTextRun({
        text: t,
        break: i === 0 ? 0 : 1
      });
    }),
    style: styles?.style ?? 'plain',
    indent: styles?.indent ?? {},
    spacing: {
      before: DEFAULTS.PARAGRAPH_BEFORE,
      after: DEFAULTS.PARAGRAPH_AFTER
    }
  });
}

export const footer = () => {
  return new Footer({
    children: [
      new Paragraph({
        children: [
          new SafeTextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  })
}

export const convertImgToBase64URL = async (url: string) => {
  // check if url is already base64
  if (base64PrefixRegex.test(url)) {
    return url.replace(base64PrefixRegex, '');
  }
  return new Promise<string>((resolve, reject) => {
    axios.get(url, { params: { cacheblock: true }, responseType: 'blob' }).then((response) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(response.data);
      fileReader.onload = () => {
        const result = (fileReader.result as string).replace(base64PrefixRegex, '');
        resolve(result);
      };
    });
  });
};

export const getLogo = (logoBase64: string, width: number = 50, height: number = 50, floating?: any) =>
  new ImageRun({
    data: Uint8Array.from(atob(logoBase64), (c) => c.charCodeAt(0)),
    transformation: {
      width,
      height,
    },
    ...floating,
  });

export const footerLogos = (reportLogo: string, clientLogo: string | undefined) => ({
  ...(clientLogo && {
    first: new Footer({
      children: [imageWrapper(getLogo(clientLogo, 100, 50, FOOTER_FLOATING))],
    }),
  }),
  default: new Footer({
    children: [
      imageWrapper(getLogo(reportLogo)),
      ...(clientLogo ? [imageWrapper(getLogo(clientLogo, 100, 50, FOOTER_FLOATING))] : []),
    ],
  }),
});

export const paragraph = (text: string, styles?: Styles) => {
  return new Paragraph({
    children: [
      new SafeTextRun({
        text: text,
        ...(styles?.textRun ?? {})
      })
    ],
    heading: styles?.heading ?? undefined,
    style: styles?.style ?? undefined,
    indent: styles?.indent ?? {},
    spacing: styles?.spacing ?? {
      before: DEFAULTS.PARAGRAPH_BEFORE,
      after: DEFAULTS.PARAGRAPH_AFTER,
    },
    alignment: styles?.alignment ?? AlignmentType.LEFT
  })
};

export const imageWrapper = (images: Paragraph | ImageRun | (Paragraph | ImageRun)[], styles?: Styles) => {
  return new Paragraph({
    children: Array.isArray(images) ? images : [images],
    alignment: styles?.alignment ?? AlignmentType.LEFT
  })
}

export const spacer = (size = DEFAULTS.PARAGRAPH_BEFORE) => {
  return new Paragraph({
    text: '',
    spacing: {
      before: size
    },
  })
}

export const getWWGLogo = async () => {
  const wwgLogoBase64 = await convertImgToBase64URL(WWGLogo);
  return getLogo(wwgLogoBase64, 200, 60, {
    floating: {
      horizontalPosition: {
        offset: 4750000,
      },
      verticalPosition: {
        offset: 600000,
      },
    },
  });
};

export const getWWGLogoLandscape = async () => {
  const wwgLogoBase64 = await convertImgToBase64URL(WWGLogo);
  return getLogo(wwgLogoBase64, 200, 60, {
    floating: {
      horizontalPosition: {
        offset: 8000000,
      },
      verticalPosition: {
        offset: 600000,
      },
    },
  });
};

interface AppendixItem {
  name: string;
  content: Table | Paragraph;
  note?: Paragraph;
}

export class Appendix {
  static appendices: AppendixItem[] = [];

  static init() {
    Appendix.appendices = [];
  }

  static add(name: string, content: Paragraph | Table, note?: Paragraph) {
    Appendix.appendices.push({
      name,
      content,
      note,
    });
    return `Appendix ${Appendix.appendices.length}`;
  }

  static render() {
    return Appendix.appendices
      .map((a, i) => [pagebreak(), heading(`Appendix ${i + 1}: ${a.name}`), a.content, ...(a.note ? [a.note] : [])])
      .flat();
  }
}

export const getBorders = (colour: string) => ({
  top: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: colour
  },
  bottom: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: colour
  },
  left: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: colour
  },
  right: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: colour
  }
});

export const spacedParagraph = (text: string, styles?: Styles) => {
  return paragraph(text, {
    spacing: {
      line: DEFAULTS.LINE_SPACING
    },
    ...styles
  });
}

export const infoParagraph = (text: string, styles?: Styles) => {
  return new Paragraph({
    children: [
      new SafeTextRun({
        text: text,
        color: 'BBBBBB',
        ...styles?.textRun
      })
    ],
    spacing: {
      line: 350
    }
  })
};
