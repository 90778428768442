import {
  AssessmentData,
  ESGCategory,
  MaterialityBoundary,
  MaterialPillar,
  MaterialTopicCategories,
} from './api/materiality-assessment';

export interface AssessmentDataRow {
  name?: string;
  score: number;
  relativeScore: number | undefined;
  utrMapping: string;
  esgs: string;
  sdgs: string;
  materialPillars: string;
  materialBoundaries: string;
}

export enum CompanySettingsPages {
  Details = 'details',
  DefaultSurveyConfig = 'default-survey-config',
}

type AssessmentDataRowKey = keyof AssessmentDataRow;

const defaultCategories: MaterialTopicCategories = { boundary: [], esg: [], materialPillar: [], sdg: [] };

export const getResultByScoreDesc = (data: AssessmentData[]): AssessmentDataRow[] =>
  data
    .slice()
    .map((row) => {
      const { categories = defaultCategories, utrMapping = [], name, score } = row;
      return {
        name,
        score,
        relativeScore: row.relativeScore,
        utrMapping: utrMapping.map((utr) => utr.code).join(', '),
        esgs: (categories.esg ?? []).join(', '),
        sdgs: (categories.sdg ?? []).join(', '),
        materialPillars: (categories.materialPillar ?? []).join(', '),
        materialBoundaries: (categories.boundary ?? []).join(', '),
      };
    })
    .sort((a, b) => b.score - a.score);

export const assessmentDataColumnMap: { [key in AssessmentDataRowKey]: string } = {
  name: 'Material topic',
  score: 'Materiality',
  relativeScore: 'Materiality',
  utrMapping: 'Mapped UTR codes',
  esgs: 'ESGs',
  sdgs: 'SDGs',
  materialPillars: 'Material pillars',
  materialBoundaries: 'Material boundaries',
};

export const Mode = {
  Financial: 'financial',
  NonFinancial: 'nonFinancial',
};

export const esgMap = {
  [ESGCategory.Environmental]: { label: 'Environment', icon: 'fa-leaf', color: 'text-ThemeSuccessMedium' },
  [ESGCategory.Social]: { label: 'Social', icon: 'fa-thumbs-up', color: 'text-ThemeChartMedium' },
  [ESGCategory.Governance]: { label: 'Governance', icon: 'fa-gavel', color: 'text-ThemeWarningMedium' },
};

export const materialPillarMap = {
  [MaterialPillar.People]: { label: 'People', icon: 'fa-people-group' },
  [MaterialPillar.Partnership]: { label: 'Partnership', icon: 'fa-handshake' },
  [MaterialPillar.Planet]: { label: 'Planet', icon: 'fa-planet-moon' },
  [MaterialPillar.Prosperity]: { label: 'Prosperity', icon: 'fa-seedling' },
  [MaterialPillar.Principle]: { label: 'Principle', icon: 'fa-file-signature' },
};

export const boundaryMap = {
  [MaterialityBoundary.Leadership]: { label: 'Leadership'},
  [MaterialityBoundary.ResearchAndDevelopment]: { label: 'Research and Development'},
  [MaterialityBoundary.SupplyChain]: { label: 'Supply Chain'},
  [MaterialityBoundary.ProductAndServices]: { label: 'Product and Services'},
  [MaterialityBoundary.Distribution]: { label: 'Distribution'},
  [MaterialityBoundary.Communities]: { label: 'Communities'},
  [MaterialityBoundary.Experiences]: { label: 'Experiences'},
};
