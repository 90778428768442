import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { getMonthOptions, getPeriodOptions, getYearDropdownOptions } from '@utils/date';
import { getFirstMonth, getFirstYear } from '@utils/surveyData';
import { ReactSelectOptions } from '../../survey/form/input/selectUtils';
import { Blueprints, ConfigFormData, ErrorState } from '../types';
import { DEFAULT_YEAR_RANGE, YEARS_BACK, YEARS_NEXT } from '@constants/date';
import { FeatureStability, SelectFactory, SelectTypes, SimpleTooltip } from '@g17eco/molecules';
import { SURVEY } from '@constants/terminology';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';

export const OPTIONS = {
  PERIOD: getPeriodOptions(),
  MONTH: getMonthOptions(),
  FIRST_YEAR: getYearDropdownOptions(DEFAULT_YEAR_RANGE),
  LAST_YEAR: getYearDropdownOptions(YEARS_BACK, YEARS_NEXT),
};

const TOOLTIP = {
  PERIOD: 'Period that you are collecting data for',
  LAST_MONTH: 'The final month you are collecting data for',
  FIRST_MONTH: 'The first month you are collecting data for',
  BLUEPRINT_VERSION: 'The version used for SDG contribution calculations. Hidden to non-staff users.',
};

const surveyVersionOptions = [
  {
    label: `${SURVEY.CAPITALIZED_SINGULAR} 2020`,
    value: Blueprints.Gri2020,
  },
  {
    label: `${SURVEY.CAPITALIZED_SINGULAR} 2022`,
    value: Blueprints.Blueprint2022,
  },
];

export enum HideField {
  BlueprintVersion = 'blueprint-version',
  Period = 'report-period',
  LastMonth = 'last-month',
  FirstMonth = 'first-month',
}

export interface OnChangeReportingProps {
  option: { value: DataPeriods | number | string } | null;
  code: string;
}

interface SurveyReportingSettingsProps {
  formDisabled?: boolean;
  isDisabled?: boolean;
  form: Pick<ConfigFormData, 'month' | 'year' | 'sourceName'> & { period?: DataPeriods };
  onChange?: ({ option, code }: OnChangeReportingProps) => void;
  reportingTitles?: {
    type: string;
    lastMonth: string;
    firstMonth: string;
  };
  errorState?: ErrorState;
  hideFields?: HideField[];
}

const defaultReportingTitles = {
  type: `${SURVEY.CAPITALIZED_ADJECTIVE} period`,
  lastMonth: `${SURVEY.CAPITALIZED_ADJECTIVE} last month`,
  firstMonth: `${SURVEY.CAPITALIZED_ADJECTIVE} first month`,
};

export const SurveyReportingSettings = (props: SurveyReportingSettingsProps) => {
  const {
    formDisabled,
    isDisabled,
    form,
    onChange = () => {},
    reportingTitles = defaultReportingTitles,
    errorState,
    hideFields = [],
  } = props;

  const isUserStaff = useAppSelector(isStaff);
  const selectedSurveyVersion = surveyVersionOptions.find((o: ReactSelectOptions) => o.value === form.sourceName);

  const selectedPeriod = OPTIONS.PERIOD.find((o: ReactSelectOptions) => o.value === form.period);
  const selectedLastMonth = OPTIONS.MONTH.find((o: ReactSelectOptions) => o.value === Number(form.month));
  const selectedLastYear = OPTIONS.LAST_YEAR.find((o) => o.value === form.year);

  const firstMonth = form.period && getFirstMonth(form.month, form.period);
  const selectedFirstMonth = OPTIONS.MONTH.find((o: ReactSelectOptions) => o.value === firstMonth);

  const firstYear = form.period && getFirstYear(form.month, form.year, form.period);
  const selectedFirstYear = OPTIONS.FIRST_YEAR.find((o) => o.value === firstYear);

  const errorFields = errorState?.fields ?? [];
  const inValidDate = errorFields.some(f => f === 'month' || f === 'year');

  return (
    <FormGroup className='mt-4' disabled={formDisabled}>
      <div className='row'>
        {hideFields.includes(HideField.Period) ? null : (
          <div className='col-12 col-md-6' data-testid='survey-type-select'>
            <Label>
              {reportingTitles.type}
              <SimpleTooltip text={TOOLTIP.PERIOD}>
                <i className='fal fa-circle-info text-ThemeIconSecondary ml-2' />
              </SimpleTooltip>
            </Label>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              isSearchable={false}
              isDisabled={isDisabled}
              placeholder={'Monthly, Quarterly, Annual'}
              className='w-100'
              onChange={(option) => onChange({ option, code: 'period' })}
              value={selectedPeriod}
              options={OPTIONS.PERIOD}
              isInvalid={errorFields.includes('period')}
            />
          </div>
        )}

        {isUserStaff && !hideFields.includes(HideField.BlueprintVersion) ? (
          <div className='col-12 col-md-6' data-testid='survey-version-select'>
            <Label>
              <FeatureStability stability={'internal'} className='mr-2' />
              Blueprint version
              <SimpleTooltip text={TOOLTIP.BLUEPRINT_VERSION}>
                <i className='fal fa-circle-info text-ThemeIconSecondary ml-2' />
              </SimpleTooltip>
            </Label>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              isDisabled={isDisabled}
              placeholder={`${SURVEY.CAPITALIZED_SINGULAR} version`}
              className='w-100'
              onChange={(option: { value: Blueprints } | null) => onChange({ code: 'sourceName', option })}
              value={selectedSurveyVersion}
              options={surveyVersionOptions}
              isInvalid={errorFields.includes('sourceName')}
            />
          </div>
        ) : null}
      </div>

      <div className='row mt-4'>
        {hideFields.includes(HideField.LastMonth) ? null : (
          <div className='col-12 col-md-6'>
            <Label>
              {reportingTitles.lastMonth}
              <SimpleTooltip text={TOOLTIP.LAST_MONTH}>
                <i className='fal fa-circle-info text-ThemeIconSecondary ml-2' />
              </SimpleTooltip>
            </Label>
            <div className='row'>
              <div className='col-12 col-md-6' data-testid='survey-month-select'>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  isDisabled={isDisabled}
                  placeholder={'Select Month'}
                  className='w-100'
                  onChange={(option) => onChange({ option, code: 'month' })}
                  value={selectedLastMonth}
                  isSearchable={true}
                  options={OPTIONS.MONTH}
                  isInvalid={inValidDate}
                />
              </div>
              <div className='col-12 col-md-6' data-testid='survey-year-select'>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  isDisabled={isDisabled}
                  placeholder={'Select Year'}
                  className='w-100'
                  onChange={(option) => onChange({ option, code: 'year' })}
                  value={selectedLastYear}
                  isSearchable={true}
                  options={OPTIONS.LAST_YEAR}
                  isInvalid={inValidDate}
                />
              </div>
            </div>
          </div>
        )}

        {hideFields.includes(HideField.FirstMonth) ? null : (
          <div className={`col-12 col-md-6 ${form.period === DataPeriods.Monthly ? 'd-none' : ''}`}>
            <Label>
              {reportingTitles.firstMonth}
              <SimpleTooltip text={TOOLTIP.FIRST_MONTH}>
                <i className='fal fa-circle-info text-ThemeIconSecondary ml-2' />
              </SimpleTooltip>
            </Label>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  placeholder={'Select last month'}
                  className='w-100'
                  isDisabled={true}
                  value={selectedFirstMonth}
                  options={OPTIONS.MONTH}
                  isInvalid={inValidDate}
                />
              </div>
              <div className='col-12 col-md-6'>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  placeholder={'Select last year'}
                  className='w-100'
                  isDisabled={true}
                  value={selectedFirstYear}
                  options={OPTIONS.FIRST_YEAR}
                  isInvalid={inValidDate}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </FormGroup>
  );
};
