import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Dashboard, { DashboardSection , DashboardDivider } from '../../dashboard';
import { TemplateDashboardHeader } from '../partials/TemplateDashboardHeader';
import { TemplateSettingsHeader } from '../partials/TemplateSettingsHeader';
import { useHistory, useParams } from 'react-router-dom';
import { useGoBack } from '../hooks/useGoBack';
import { useEffect, useMemo, useState } from 'react';
import { MergeTagsButton } from '../partials/MergeTagsButton';
import { SubsidiarySelectionTable } from '../../downloads/partials/SubsidiarySelectionTable';
import { TemplateConfigFormData } from '../types';
import { updateFn } from '../../form/common';
import {
  useCreateTemplateMutation,
  useGetTemplateByIdQuery,
  useUpdateTemplateMutation,
} from '../../../api/survey-templates';
import { TemplateMessage } from '../constants';
import { getAnalytics } from '../../../services/analytics/AnalyticsService';
import { AnalyticsEvents } from '../../../services/analytics/AnalyticsEvents';
import { generateUrl } from '../../../routes/util';
import { ROUTES } from '../../../constants/routes';
import { hasInvalidTags } from '../utils';
import { SerializedError } from '@reduxjs/toolkit';
import { SurveyType } from '../../../types/survey';
import { SURVEY } from '@constants/terminology';
import { BasicAlert, LoadingPlaceholder } from '@g17eco/molecules';
import { toFormScheduledDates } from '@components/survey-configuration/utils';

interface AggregatedTemplateConfigFormData extends TemplateConfigFormData {
  surveyName: string;
  hasCurrentInitiatives?: boolean;
  reportingLevels: string[];
}

const initialState: AggregatedTemplateConfigFormData = {
  type: SurveyType.Aggregation,
  initiativeId: '',
  name: '',
  surveyName: '',
  hasCurrentInitiatives: false,
  reportingLevels: [],
};

const analytics = getAnalytics();

const AggregatedTemplateMessage = ({
  isCreated,
  isUpdated,
  error,
}: {
  isCreated: boolean;
  isUpdated: boolean;
  error?: {
    message: string;
  } | SerializedError;
}) => {
  switch (true) {
    case isCreated:
      return <BasicAlert type={'success'}>{TemplateMessage.AggregatedCreated}</BasicAlert>;
    case isUpdated:
        return <BasicAlert type={'success'}>{TemplateMessage.AggregatedUpdated}</BasicAlert>;
    case !!error:
      return <BasicAlert type={'danger'}>{error?.message}</BasicAlert>;
    default:
      return null;
  }
};

export const AggregatedConfiguration = () => {
  const { initiativeId = '', templateId = '' } = useParams<{ initiativeId: string; templateId: string }>();
  const [form, setForm] = useState<AggregatedTemplateConfigFormData>({ ...initialState, initiativeId });
  const history = useHistory();
  const goBack = useGoBack(initiativeId);
  const [createTemplate, createResult] = useCreateTemplateMutation();
  const [updateTemplate, updateResult] = useUpdateTemplateMutation();
  const { data: currentTemplate } = useGetTemplateByIdQuery(templateId, { skip: !templateId });
  const isCreating = !templateId;

  const updateForm: updateFn = (update) =>
    setForm({ ...form, [update.code]: update.value });

  const hasChanged = useMemo(() => {
    if (currentTemplate?.name !== form.name) {
      return true;
    }
    if (currentTemplate?.surveyName !== form.surveyName) {
      return true;
    }
    if (currentTemplate?.hasCurrentInitiatives !== form.hasCurrentInitiatives) {
      return true;
    }
    if (currentTemplate?.isPrivate !== form.isPrivate) {
      return true;
    }
    if (currentTemplate?.reportingLevels && form.reportingLevels) {
      return (
        currentTemplate.reportingLevels.length !== form.reportingLevels.length ||
        form.reportingLevels.some((initiativeId) => !currentTemplate.reportingLevels?.includes(initiativeId))
      );
    }
    return false;
  }, [form, currentTemplate]);

  const handleCreateSubmit = async () => {
    const { data: createdTemplate } = await createTemplate(form).unwrap();
    if (createdTemplate) {
      await analytics.track(AnalyticsEvents.SurveyTemplateCreated, {
        type: createdTemplate.type,
        templateId: createdTemplate._id,
        initiativeId: createdTemplate.initiativeId,
      });
      return history.push({
        pathname: generateUrl(ROUTES.SURVEY_TEMPLATES, { initiativeId }),
        search: history.location.search,
      });
    }
  };

  const handleUpdateSubmit = async () => {
    const { data: updatedTemplate } = await updateTemplate(form).unwrap();
    if (updatedTemplate) {
      await analytics.track(AnalyticsEvents.SurveyTemplateUpdated, {
        type: updatedTemplate.type,
        templateId: updatedTemplate._id,
        initiativeId: updatedTemplate.initiativeId,
      });
    }
  };

  useEffect(() => {
    if (currentTemplate) {
      return setForm((prev) => ({
        ...prev,
        ...currentTemplate,
        scheduledDates: toFormScheduledDates(currentTemplate.scheduledDates),
      }));
    }
  }, [currentTemplate]);

  const isDisabled = !form.name || !hasChanged || hasInvalidTags(form.surveyName) || form.reportingLevels.length === 0;

  return (
    <Dashboard>
      <TemplateSettingsHeader />
      <DashboardSection header={<TemplateDashboardHeader title={`Combined ${SURVEY.SINGULAR} template`} />}>
        <BasicAlert type={'danger'}>{hasInvalidTags(form.surveyName) ? 'Merge tag does not exist' : ''}</BasicAlert>
        <AggregatedTemplateMessage
          isCreated={createResult.isSuccess}
          isUpdated={updateResult.isSuccess}
          error={createResult.error || updateResult.error}
        />
        <LoadingPlaceholder
          className='mt-4'
          count={1}
          height={800}
          isLoading={createResult.isLoading || updateResult.isLoading}
        >
          <Form className='survey-config-form'>
            <div className='mt-4 mb-6 position-relative'>
              <h5>Template name*</h5>
              <Input
                placeholder={'Name of template i.e. Monthly company aggregation'}
                value={form.name}
                onChange={(el) => updateForm({ code: 'name', value: el.target.value })}
                data-testid='combined-template-name-input'
              />
              <h5>{SURVEY.CAPITALIZED_SINGULAR} name</h5>
              <Input
                placeholder={`Name used when creating combined ${SURVEY.SINGULAR} i.e. Annual aggregated results for 2023`}
                value={form.surveyName}
                onChange={(el) => updateForm({ code: 'surveyName', value: el.target.value })}
                data-testid='combined-template-survey-name-input'
              />
              <MergeTagsButton
                surveyName={form.surveyName}
                updateName={(value: string) => updateForm({ code: 'surveyName', value })}
              />
            </div>
            <DashboardDivider />
            <div className='mt-4'>
              <h5>Include active subsidiaries data</h5>
              <FormGroup check>
                <Input
                  type={'checkbox'}
                  checked={Boolean(form.hasCurrentInitiatives)}
                  onChange={(e) =>
                    updateForm({
                      code: 'hasCurrentInitiatives',
                      value: e.target.checked,
                    })
                  }
                />
                <Label check>Include current subsidiary data in generated combined {SURVEY.PLURAL}</Label>
              </FormGroup>
            </div>
            <div className='mt-4'>
              <h5>Create combined {SURVEY.SINGULAR} at these subsidiaries</h5>
              <SubsidiarySelectionTable
                initiativeId={initiativeId}
                selectedIds={form.reportingLevels}
                handleSetSelectedIds={(ids: string[]) => {
                  return updateForm({ code: 'reportingLevels', value: ids });
                }}
              />
            </div>
            <div className='mt-4 text-right'>
              <Button color='link' onClick={goBack} className='mr-3'>
                Cancel
              </Button>
              <Button
                color='primary'
                className='submit-button'
                disabled={isDisabled}
                onClick={isCreating ? handleCreateSubmit : handleUpdateSubmit}
                data-testid='combined-survey-template-submit-btn'
              >
                {isCreating ? 'Create template' : 'Update template'}
              </Button>
            </div>
          </Form>
        </LoadingPlaceholder>
      </DashboardSection>
    </Dashboard>
  );
};
