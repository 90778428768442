import { FormScheduledDate, SurveyModelMinData, UnitConfig } from '../../model/surveyData';
import { JobStatus } from '../../types/background-jobs';
import { InitiativeMin } from '../../types/initiative';
import { SurveyType } from '../../types/survey';
import { DataPeriods } from '../../types/universalTracker';
import { Blueprints, ConfigFormData } from '../survey-configuration/types';
import { MergeTags } from './constants';

export interface RouteParams {
  initiativeId: string;
}

type NoteProps = Pick<SurveyModelMinData,
  | 'noteRequired'
  | 'noteInstructions'
  | 'noteInstructionsEditorState'
>

export interface SurveyTemplateMinData
  extends NoteProps, Pick<
    SurveyModelMinData,
    | 'verificationRequired'
    | 'evidenceRequired'
    | 'scope'
    | 'initiativeId'
    | 'unitConfig'
    | 'deadlineDate'
    | 'scheduledDates'
  > {
  type: SurveyType;
  isPrivate?: boolean;
  _id: string;
  name: string;
  surveyName?: string;
  hasCurrentInitiatives?: boolean;
  reportingLevels?: string[];
}

export interface SurveyTemplate extends SurveyTemplateMinData {
  created: string;
  lastUpdated: string;
  lastUsed?: string;
  deletedDate?: string;
}

export interface TemplateConfigFormData extends NoteProps {
  _id?: string;
  type: SurveyType;
  evidenceRequired?: boolean;
  verificationRequired?: boolean;
  isPrivate?: boolean;
  initiativeId: string;
  name: string;
  unitConfig?: UnitConfig;
  surveyName?: string;
  hasCurrentInitiatives?: boolean;
  reportingLevels?: string[];
  deadlineDate?: string;
  scheduledDates?: FormScheduledDate[];
}

export interface TemplateTableStats extends SurveyTemplate {
  initiative: InitiativeMin;
}

export enum NavCode {
  Configuration = 'configuration',
  Scope = 'scope',
  BulkSurveysCreate = 'bulk-surveys-create',
  History = 'history',
  Subsidiary = 'subsidiary',
  AggregatedConfiguration = 'aggregated-configuration',
  BulkAggregatedCreate = 'bulk-aggregated-create',
}

export interface BulkSurveysFormData
  extends Omit<ConfigFormData, 'sourceName' | 'reportingLevel' | 'reportingCompany'> {
  _id: string;
  reportingLevels?: string[];
  surveyName?: string;
}

export interface BulkAggregatedSurveysFormData {
  period?: DataPeriods;
  year?: number;
  month?: number;
}

export type TemplateSnapshot = Pick<
  SurveyTemplate,
  | 'type'
  | 'name'
  | 'isPrivate'
  | 'evidenceRequired'
  | 'verificationRequired'
  | 'lastUpdated'
  | 'scope'
  | 'unitConfig'
  | 'noteInstructions'
  | 'noteInstructionsEditorState'
> & {
  sourceName: Blueprints;
  period: DataPeriods;
  effectiveDate: string;
  reportingLevels?: string[];
  hasCurrentInitiatives?: boolean;
};

export interface TemplateHistory {
  _id: string;
  templateId: string;
  initiativeId: string;
  snapshot: TemplateSnapshot;
  results: BulkSurveysResult[];
  completedDate?: string;
  deletedDate?: string;
  created: string;
  lastUpdated?: string;
}

interface SurveyResultInitiative {
  name: string;
  _id: string;
}

interface ResultDetail {
  message: string;
  created: Date;
}

export interface BulkSurveysResult {
  initiativeId: string;
  initiative?: SurveyResultInitiative;
  status: JobStatus;
  surveyId?: string;
  details?: ResultDetail;
}

export interface MergeOption {
  name: string;
  code: MergeTags;
}
