import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadSurvey } from '@actions/survey';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { useAppSelector } from '@reducers/index';
import { AnalyticsEvents } from '@services/analytics/AnalyticsEvents';
import { getAnalytics } from '@services/analytics/AnalyticsService';
import G17Client from '@services/G17Client';
import { loadCustomMetricGroupsBySurveyId } from '@g17eco/slices/metricGroupsSlice';
import { DownloadType, ScopeTotals, VisibilityStatus } from '@g17eco/types/download';
import { HistoricalReportData } from '@g17eco/types/reportData';
import { DownloadButton } from '@components/button/DownloadButton';
import Loader from '@components/loader';
import { getSGXMetric, getSustainabilityReport } from '../outputs';
import { reportDataWithHistoryScope } from '../reportApi';
import { EnergyConsumptionDataSource } from '../sgx-metric-report/data/EnergyConsumption';
import { GHGEmissionsDataSource } from '../sgx-metric-report/data/GHGEmissions';
import { GHGEmissionsIntensityDataSource } from '../sgx-metric-report/data/GHGEmissionsIntensity';
import { GovernanceDataSource } from '../sgx-metric-report/data/Governance';
import { SocialDataSource } from '../sgx-metric-report/data/Social';
import { WaterConsumptionDataSource } from '../sgx-metric-report/data/WaterConsumption';
import { DownloadSettingModal, DownloadSettingPropType, OptionTitle } from '@components/downloads/DownloadSettingModal';
import GenerateCharts from '../sgx-metric-report/GenerateCharts';
import { getDocVisibility, getDownloadScope, getScopeGroupData } from '../sgx-metric-report/downloadScope';
import { convertToUtrvStatuses, DownloadSettingsType } from '@components/downloads/util/downloadReportHandler';
import { getScopeModules } from '@utils/survey-scope';
import { handleSettingsChange } from '@components/downloads/util/custom';
import { AppConfig } from '@g17eco/types/app';
import { LoadingPlaceholder } from '@g17eco/molecules';

interface Props {
  initiativeId: string;
  surveyId: string;
  disabled?: boolean;
  publicQuestionsOnly?: boolean;
  code?: string;
  requiredScope?: string,
  modalTitleOptions?: {
    word?: OptionTitle,
    simple?: OptionTitle,
    detailed?: OptionTitle
  }
  totals?: ScopeTotals;
  defaultDownloadSettings?: Partial<DownloadSettingsType>;
  defaultDownloadOptions?: AppConfig['settings']['defaultDownloadOptions'];
}

export interface DataSource {
  loaded: boolean;
  width?: number;
  height?: number;
  chart?: string;
}

export interface DataSources {
  ghgEmissions: GHGEmissionsDataSource;
  ghgEmissionsIntensity: GHGEmissionsIntensityDataSource;
  energyConsumption: EnergyConsumptionDataSource;
  waterConsumption: WaterConsumptionDataSource;
  social: SocialDataSource;
  governance: GovernanceDataSource;
}
const initialDataState = {
  loaded: false,
};

const initialDataSourcesState: DataSources = {
  ghgEmissions: initialDataState,
  ghgEmissionsIntensity: initialDataState,
  energyConsumption: initialDataState,
  waterConsumption: initialDataState,
  social: initialDataState,
  governance: initialDataState,
};

interface DownloadData {
  questionData: HistoricalReportData[];
  targets: SurveyModelMinimalUtrv[];
}

const SGXTag = 'sgx_metrics';

export const SustainabilityDownloadButton = ({
  code,
  requiredScope,
  surveyId,
  initiativeId,
  disabled,
  modalTitleOptions,
  totals,
  defaultDownloadSettings,
  defaultDownloadOptions,
}: Props) => {
  const surveyState = useAppSelector((state) => state.survey);
  const { data: metricGroups } = useAppSelector((state) => state.customMetricSurveyGroups);

  const [downloading, setDownloading] = useState(false);
  const [downloaded, setDownloaded] = useState(true);
  const [downloadData, setDownloadData] = useState<DownloadData>({ questionData: [], targets: [] });

  const [dataSourcesState, setData] = useState(initialDataSourcesState);
  const [isLoaded, setLoaded] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [downloadSettings, setDownloadSettings] = useState<DownloadSettingsType>({
    selectedScopes: [], // loaded from loadSurveyScope below
    visibility: VisibilityStatus.Include,
    ...defaultDownloadSettings,
  });

  const { displayUserInput = false } = downloadSettings;

  const dispatch = useDispatch();
  const analytics = getAnalytics();

  const downloadDocx = async () => {
    setDownloaded(false);
    setDownloading(true);
    const appliedStatus = downloadSettings.assuranceStatus ?? downloadSettings.status;
    const statuses = convertToUtrvStatuses(appliedStatus);

    return reportDataWithHistoryScope(surveyId, {
      scope: getDownloadScope(downloadSettings.selectedScopes),
      visibility: getDocVisibility(downloadSettings.visibility),
      targets: true, // Include targets
      ...statuses,
      displayMetricOverrides: downloadSettings.displayMetricOverrides, // override isPrivate if displayMetricOverrides = true
    })
      .then((data) => {
        setDownloadData({ questionData: data.historical, targets: data.targets });

        return analytics.track(AnalyticsEvents.SurveyDataDownloaded, {
          initiativeId,
          surveyId: surveyId,
          source: 'downloads_page',
          scopeValue: code,
          type: 'docx',
        });
      })
      .catch((e) => console.log(e.message));
  };

  const updateData = useCallback(
    (key: string, data: DataSource) => {
      setData((dataSources) => ({
        ...dataSources,
        [key]: data,
      }));
    },
    [setData]
  );

  const handleCloseModal = () => {
    setOpenSettings(false);
  };

  useEffect(() => {
    if (surveyId !== surveyState.surveyId) {
      dispatch(loadSurvey(surveyId));
      dispatch(loadCustomMetricGroupsBySurveyId(surveyId));
    }
  }, [surveyId, surveyState.surveyId, dispatch]);

  useEffect(() => {
    const allLoaded = Object.keys(dataSourcesState).every((k) => dataSourcesState[k as keyof DataSources].loaded);
    setLoaded(allLoaded);
  }, [dataSourcesState]);

  useEffect(() => {
    if (isLoaded && downloading) {
      setDownloading(false);
      G17Client.getSurveyListItem(surveyId)
        .then(async (survey) => {
          const blueprintContributions = await G17Client.getBlueprintContributions(survey.sourceName);
          return {
            blueprintContributions,
            survey,
          };
        })
        .then(({ survey, blueprintContributions }) => {
          if (code === SGXTag) {
            return getSGXMetric({
              survey,
              dataSources: dataSourcesState,
              questionData: downloadData.questionData,
              targets: downloadData.targets,
              scopeGroupHistoricalData: getScopeGroupData(
                downloadData.questionData,
                downloadSettings.selectedScopes,
                blueprintContributions
              ),
              visibilityStatus: downloadSettings.visibility,
              displayUserInput,
            });
          }
          return getSustainabilityReport({
            survey,
            dataSources: dataSourcesState,
            questionData: downloadData.questionData,
            targets: downloadData.targets,
            scopeGroupHistoricalData: getScopeGroupData(
              downloadData.questionData,
              downloadSettings.selectedScopes,
              blueprintContributions
            ),
            visibilityStatus: downloadSettings.visibility,
            displayUserInput,
          });
        })
        .then(() => {
          setDownloaded(true);
          setData(initialDataSourcesState);
        })
        .catch((e) => console.log(e));
    }
  }, [code, dataSourcesState, surveyId, isLoaded, downloading, downloadData, downloadSettings, displayUserInput]);

  const loadSurveyScope = () => {
    if ('scope' in surveyState.data && surveyState.data.scope) {
      const scope = surveyState.data.scope;

      const selectedScopes = getScopeModules({ scope, metricGroups });
      return setDownloadSettings({ ...downloadSettings, selectedScopes });
    }
    setDownloadSettings({ ...downloadSettings, selectedScopes: [] });
  };

  const downloadModalProps: DownloadSettingPropType = {
    title: 'Sustainability report',
    code,
    initiativeId,
    titleOptions: {
      ...modalTitleOptions
    },
    requiredScope: requiredScope,
    isOpen: openSettings,
    downloadSettings,
    totals,
    defaultDownloadOptions,
    handleClose: handleCloseModal,
    handleChange: (event) => handleSettingsChange(event, setDownloadSettings, downloadSettings),
    onDownload: async (type, config) => {
      const appliedStatus = downloadSettings.assuranceStatus ?? downloadSettings.status;
      const statuses = convertToUtrvStatuses(appliedStatus);
      const {
        displayMetricOverrides,
        displayTag,
        displayUserInput
      } = downloadSettings;
      switch (type) {
        case DownloadType.Csv:
        case DownloadType.Xlsx:
          return G17Client.downloadSurveySimple({
            surveyId,
            type,
            downloadScope: {
              scope: getDownloadScope(downloadSettings.selectedScopes),
              visibility: downloadSettings.visibility,
              ...statuses,
              displayMetricOverrides,
              displayUserInput,
              displayTag,
            },
          });
        case DownloadType.Docx:
          return downloadDocx();
        case DownloadType.Xhtml:
          return G17Client.generateXhtmlReport({
            surveyId,
            downloadScope: {
              scope: getDownloadScope(downloadSettings.selectedScopes),
              visibility: downloadSettings.visibility,
              ...statuses,
              displayMetricOverrides,
              displayUserInput,
              displayTag,
            },
            ...config,
          });
        default:
          return;
      }
    },
  };

  return (
    <div className='ctl-report-generator mt-5'>
      {!downloaded ? <Loader /> : <></>}
      {downloadData.questionData.length > 0 ? (
        <GenerateCharts
          updateData={updateData}
          questionData={downloadData.questionData}
          visibility={downloadSettings.visibility}
        />
      ) : null}
      <LoadingPlaceholder testId={'download-report-placeholder'} height={36} isLoading={!surveyState.loaded && !surveyState.errored}>
        <DownloadButton
          testId={'download-report-btn'}
          className='doc-btn'
          color='secondary'
          outline={true}
          disabled={disabled}
          onClick={() => {
            loadSurveyScope();
            setOpenSettings(true);
          }}
        >
          Download report
          <i className='fas fa-file-download ml-2' />
        </DownloadButton>
      </LoadingPlaceholder>
      {downloadModalProps.isOpen ? <DownloadSettingModal {...downloadModalProps} />:  null}
    </div>
  );
};
