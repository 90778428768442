import React, { useMemo, useState } from 'react';
import { CurrentUserData } from '@reducers/current-user';
import { MinReportingLevel, SelectionSVG, TreeNode } from './types';
import { BaseOrgMapProps, useBaseOrgMap } from './hooks/useBaseOrgMap';
import { InitiativePlain, RootInitiativeData } from '../../types/initiative';
import { useAppSelector } from '../../reducers';
import {
  archivedRoot,
  createArchivedInitiativeTree,
  getInitiativeTree,
  getInitiativeTypeTree,
} from '@selectors/initiativeTree';
import { getArchivedNodeStyles } from './utils';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ActivateSubsidiaryModal } from './partials/activate-subsidiary-modal/ActivateSubsidiaryModal';
import { FeaturePermissions } from '@services/permissions/FeaturePermissions';
import { InitiativePermissions } from '@services/permissions/InitiativePermissions';
import { PermissionDenied } from '@g17eco/molecules';
import { ExpandActions } from './partials/ExpandActions';

interface InitiativeStructureArchivedProps {
  currentUser: CurrentUserData;
  rootOrg: RootInitiativeData;
  archivedInitiatives: InitiativePlain[];
  expandedNodes: string[];
  setExpandedNodes: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface ArchivedReportingLevel extends MinReportingLevel {
  parentId?: string;
}

export const InitiativeStructureArchived = (props: InitiativeStructureArchivedProps) => {
  const { rootOrg, currentUser, archivedInitiatives, expandedNodes, setExpandedNodes } = props;
  const history = useHistory();
  const limitReportingLevels = useAppSelector(FeaturePermissions.getLimitReportingLevels);
  const activeInitiativeTreeList = useAppSelector(getInitiativeTypeTree);
  const activeInitiativeTree = useAppSelector(getInitiativeTree);
  const [selectedInitiative, setSelectedInitiative] = useState<ArchivedReportingLevel | undefined>(undefined);
  const canManage = InitiativePermissions.canManageInitiative(currentUser, rootOrg._id);

  const toggleModal = () => {
    setSelectedInitiative(undefined);
  };

  const archivedInitiativeTree = useMemo(
    () =>
      createArchivedInitiativeTree({
        archivedInitiatives,
        existingInitiatives: activeInitiativeTreeList,
      }),
    [archivedInitiatives, activeInitiativeTreeList]
  );

  const getTreeFilterRoot = () => {
    return archivedInitiativeTree.children[0];
  };

  const actionButtons = {
    html: canManage ? `<button id="undo_archive" class="btn-link p-1 mr-2">
            <i class=\'fal fa-rotate-left fa-xl text-ThemeIconPrimary\' id="undo_icon"></i>
          </button>` : '',
    onClick: (svg: SelectionSVG) => (event: React.MouseEvent<HTMLElement, MouseEvent>, d: TreeNode) => {
      const target = event.target as HTMLElement;
      if (!d.data.id || d.data.disabled) {
        return;
      }
      const [initiativeId] = d.data.id.split('_');
      if (target.id === 'undo_archive' || target.id === 'undo_icon') {
        setSelectedInitiative({ initiativeId, name: d.data.name, parentId: d.data.parentId });
      }
    },
  };

  const handleBackButton = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    history.goBack();
  };

  const baseOrgMapProps: BaseOrgMapProps = {
    initiativeTree: archivedInitiativeTree,
    currentId: archivedRoot._id,
    getTreeFilterRoot,
    actionButtons,
    customStyles: getArchivedNodeStyles(),
    expandedNodes,
    setExpandedNodes,
  };
  const { component, handleReset, handleZoom, zoomInDisabled, zoomOutDisabled, getInitiativeStructure } =
    useBaseOrgMap(baseOrgMapProps);

  if (!canManage) {
    return <PermissionDenied />
  }


  return (
    <>
      <div className='mindmap-wrapper'>
        <div className='backButton'>
          <Button type='button' color='link' onClick={handleBackButton}>
            <i className='fas fa-arrow-left mr-2'></i>
            Back
          </Button>
        </div>
        <div className='mindmap-menu'>
          <div className='row d-flex'>
            <div className='col col-auto'>
              <button
                disabled={zoomInDisabled}
                onClick={() => handleZoom(2)}
                className='fa-light fa-magnifying-glass-plus'
              ></button>
              <button
                disabled={zoomOutDisabled}
                onClick={() => handleZoom(0.5)}
                className='fa-light fa-magnifying-glass-minus'
              ></button>
              Zoom
            </div>

            <div className='col col-auto'>
              <div className='row d-flex'>
                <button onClick={() => handleReset()} className='col col-auto d-flex gap-2'>
                  Reset
                  <span className='fa-light fa-expand'></span>
                </button>
                <ExpandActions
                  getInitiativeStructure={getInitiativeStructure}
                  initiativeList={archivedInitiatives}
                  expandedNodes={expandedNodes}
                  setExpandedNodes={setExpandedNodes}
                />
              </div>
            </div>
          </div>

          <div className='d-flex flex-column text-end mt-3'>
            <div>Limit: {limitReportingLevels}</div>
            <div>Active: {activeInitiativeTreeList.length}</div>
            <div>Remaining: {limitReportingLevels - activeInitiativeTreeList.length}</div>
            <div>Archived: {archivedInitiatives.length}</div>
          </div>
        </div>
        <div className='dont_translate'>{component}</div>
      </div>
      <ActivateSubsidiaryModal
        key={`${rootOrg._id}-${selectedInitiative?.initiativeId}`}
        selectedInitiative={selectedInitiative}
        activeInitiativeTree={activeInitiativeTree}
        initiativeTreeList={activeInitiativeTreeList}
        toggleModal={toggleModal}
        rootOrg={rootOrg}
      />
    </>
  );
};
