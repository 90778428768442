import { getBorders, spacer } from '@components/report-output/document-structure';
import { SafeTextRun } from '@utils/docx';
import {
  Paragraph,
  AlignmentType,
  TableRow,
  TableCell,
  Table,
  WidthType,
  TableLayoutType,
  IShadingAttributesProperties,
} from 'docx';
import {
  AssessmentData,
  MaterialityBoundary,
  MaterialPillar,
  UtrMapping,
} from '@apps/materiality-tracker/api/materiality-assessment';
import { createArrayOfNumbers } from '@utils/array';
import { Framework, frameworks, Standard, standards } from '@g17eco/core';
import { UniversalTrackerBlueprintMin } from '@g17eco/types/universalTracker';

const TABLE_WIDTH = 9000;
const STYLES = {
  TABLE_HEADER_SHADING: {
    fill: 'f5f5f0',
  },
  TABLE_BORDER_COLOUR: '6a6c70',
  LINE_SPACING: 300,
  INFO_TEXT: {
    color: 'BBBBBB',
    size: 20,
  },
  TABLE_CELL: {
    size: 20,
    color: '434343',
    alignment: AlignmentType.LEFT,
  },
  TABLE: {
    width: {
      size: TABLE_WIDTH,
      type: WidthType.DXA,
    },
    layout: TableLayoutType.AUTOFIT,
  },
};

const MARGINS_SM = {
  left: 75,
  right: 75,
  top: 75,
  bottom: 75,
};

const boundariesMap = {
  [MaterialityBoundary.Leadership]: 'Leadership',
  [MaterialityBoundary.ResearchAndDevelopment]: 'R&D',
  [MaterialityBoundary.SupplyChain]: 'SCM',
  [MaterialityBoundary.ProductAndServices]: 'Service',
  [MaterialityBoundary.Distribution]: 'Distribution',
  [MaterialityBoundary.Communities]: 'Community',
  [MaterialityBoundary.Experiences]: 'Experience',
};

const pillarShadingMap = {
  [MaterialPillar.People]: {
    main: '38761d',
    sub: 'b6d7a8',
  },
  [MaterialPillar.Partnership]: {
    main: '0b5394',
    sub: '9fc5e8',
  },
  [MaterialPillar.Planet]: {
    main: 'bf9000',
    sub: 'ffe599',
  },
  [MaterialPillar.Prosperity]: {
    main: '85200c',
    sub: 'dd7e6b',
  },
  [MaterialPillar.Principle]: {
    main: '351c75',
    sub: 'b4a7d6',
  },
};

const topTopicShadingMap: { [index: number]: string } = {
  0: 'b4a7d6',
  1: 'd2cae9',
  2: 'eae5f5',
};

export class AssessmentTableGenerator {
  /** Top 3 by scores topics for each materiality pillar */
  private topicsByPillarsMap: Map<string, AssessmentData[]>;
  /** Unique top topics by pillars */
  private uniqueTopics: AssessmentData[];
  /** Top 3 by scores topics for each materiality boundary based on unique topics */
  private topicsByBoundariesMap: Map<string, AssessmentData[]>;
  /** All topics scopes */
  private topicScopes: Map<string, Standard | Framework>;
  /** Blueprint for question lookup */
  private blueprintQuestions: Map<string, UniversalTrackerBlueprintMin>;

  constructor({
    result,
    questions,
  }: {
    readonly result: AssessmentData[];
    readonly questions: UniversalTrackerBlueprintMin[];
  }) {
    // According to the template notes, need to use topics with pillars as a original data source
    this.topicsByPillarsMap = this.getTopicsByPillarMap(result);
    this.uniqueTopics = this.getUniqueTopics();
    this.topicsByBoundariesMap = this.getTopicsByBoundaryMap(this.uniqueTopics);
    this.topicScopes = new Map<string, Standard | Framework>([
      ...Object.entries(standards),
      ...Object.entries(frameworks),
    ]);
    this.blueprintQuestions = new Map<string, UniversalTrackerBlueprintMin>(questions.map((q) => [q.code, q]));
  }

  private getTopicsByPillar(result: AssessmentData[], pillar: MaterialPillar) {
    return result
      .filter((topic) => topic.categories?.materialPillar?.includes(pillar))
      .sort((a, b) => b.score - a.score)
      .slice(0, 3);
  }

  private getTopicsByPillarMap(result: AssessmentData[]) {
    return new Map(
      Object.values(MaterialPillar).map<[string, AssessmentData[]]>((pillar) => [
        pillar,
        this.getTopicsByPillar(result, pillar),
      ])
    );
  }

  private getTopicsByBoundary(result: AssessmentData[], boundary: MaterialityBoundary) {
    return result.filter((topic) => topic.categories?.boundary?.includes(boundary)).sort((a, b) => b.score - a.score);
  }

  private getTopicsByBoundaryMap(result: AssessmentData[]) {
    return new Map(
      Object.values(MaterialityBoundary).map<[string, AssessmentData[]]>((boundary) => [
        boundary,
        this.getTopicsByBoundary(result, boundary),
      ])
    );
  }

  private getUniqueTopics() {
    return Array.from(this.topicsByPillarsMap.values())
      .flat()
      .reduce<AssessmentData[]>((acc, topic) => {
        if (acc.some((t) => t.code === topic.code)) {
          return acc;
        }
        acc.push(topic);
        return acc;
      }, []);
  }

  private createTableCell({
    text,
    styles: { alignment, shading, color } = {},
  }: {
    text?: string | string[];
    styles?: { alignment?: AlignmentType; color?: string; shading?: IShadingAttributesProperties };
  }) {
    const mergedStyles = { ...STYLES.TABLE_CELL, ...(color ? { color } : {}) };
    const paragraph = Array.isArray(text)
      ? new Paragraph({
          children: text.map((subText) => new SafeTextRun({ ...mergedStyles, text: subText })),
          alignment,
        })
      : new Paragraph({
          children: [new SafeTextRun({ ...mergedStyles, text })],
          alignment,
        });
    return new TableCell({
      children: [paragraph],
      margins: MARGINS_SM,
      borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
      shading,
    });
  }

  private getTopicName(topic: AssessmentData) {
    return topic.name || topic.code;
  }

  private getQuestionScope(utr: UtrMapping) {
    const question = this.blueprintQuestions.get(utr.code);
    if (!question) {
      return '';
    }
    // Expected output: [UTR standard]
    return this.topicScopes.get(question.type)?.name || '';
  }

  private getQuestionInfo(utr: UtrMapping) {
    const question = this.blueprintQuestions.get(utr.code);
    if (!question) {
      return '';
    }
    // Expected output: [UTR standard]: [URT Title]
    return `${this.topicScopes.get(question.type)?.name}: ${question.valueLabel}`;
  }

  public getStandardsTables() {
    return Object.values(MaterialPillar).flatMap((pillar) => {
      const { main, sub } = pillarShadingMap[pillar];
      const initialRows = [
        new TableRow({
          children: [
            this.createTableCell({
              text: pillar.toUpperCase(),
              styles: { alignment: AlignmentType.CENTER, shading: { fill: main }, color: 'ffffff' },
            }),
          ],
        }),
        new TableRow({
          children: [
            this.createTableCell({ text: 'MATERIAL TOPIC', styles: { shading: { fill: sub } } }),
            this.createTableCell({ text: 'STANDARDS & FRAMEWORKS', styles: { shading: { fill: sub } } }),
          ],
        }),
      ];
      return [
        new Table({
          ...STYLES.TABLE,
          rows: initialRows.concat(
            createArrayOfNumbers(0, 2).map((index) => {
              const topic = this.topicsByPillarsMap.get(pillar)?.[index];
              const topicName = this.createTableCell({ text: topic?.name || topic?.code });
              const uniqueStandards = (topic?.utrMapping || []).reduce<string[]>((acc, utr) => {
                const standard = this.getQuestionScope(utr);
                if (!standard || acc.includes(standard)) {
                  return acc;
                }
                acc.push(standard);
                return acc;
              }, []);
              const mappedScopes = this.createTableCell({ text: uniqueStandards.join(', ') });
              return new TableRow({
                children: [topicName, mappedScopes],
              });
            })
          ),
          columnWidths: Array.from({ length: 2 }, (_) => TABLE_WIDTH / 2),
        }),
        spacer(),
      ];
    });
  }

  public getBoundariesTables() {
    const topicWidth = Math.floor(TABLE_WIDTH * 0.3);
    const boundaryWidth = Math.floor(TABLE_WIDTH * 0.1);

    return Object.values(MaterialPillar).flatMap((pillar) => {
      const { main, sub } = pillarShadingMap[pillar];
      const initialRows = [
        new TableRow({
          children: [
            this.createTableCell({
              text: pillar.toUpperCase(),
              styles: { alignment: AlignmentType.CENTER, shading: { fill: main }, color: 'ffffff' },
            }),
          ],
        }),
        new TableRow({
          children: [
            this.createTableCell({ text: 'MATERIAL TOPIC', styles: { shading: { fill: sub } } }),
            ...Object.values(boundariesMap).map((boundary) =>
              this.createTableCell({
                text: boundary,
                styles: { alignment: AlignmentType.CENTER, shading: { fill: sub } },
              })
            ),
          ],
        }),
      ];

      return [
        new Table({
          ...STYLES.TABLE,
          rows: initialRows.concat(
            createArrayOfNumbers(0, 2).map((index) => {
              const topic = this.topicsByPillarsMap.get(pillar)?.[index];
              const topicName = this.createTableCell({ text: topic?.name || topic?.code });

              const boundaryValues = Object.keys(boundariesMap).map((boundary) => {
                if (topic?.categories?.boundary?.includes(boundary as MaterialityBoundary)) {
                  return this.createTableCell({ text: '✓', styles: { alignment: AlignmentType.CENTER } });
                }
                return this.createTableCell({ text: '', styles: { alignment: AlignmentType.CENTER } });
              });

              return new TableRow({
                children: [topicName, ...boundaryValues],
              });
            })
          ),
          columnWidths: [topicWidth, ...Array.from({ length: Object.values(MaterialityBoundary).length }, (_) => boundaryWidth)],
        }),
        spacer(),
      ];
    });
  }

  public getTopTopicsPerBoundaryTable() {
    const initialRows = [
      new TableRow({
        children: Object.values(boundariesMap).map((boundary) =>
          this.createTableCell({ text: boundary, styles: { shading: { fill: '351c75' }, color: 'ffffff' } })
        ),
      }),
    ];
    const maxRowCount = Math.max(...Array.from(this.topicsByBoundariesMap.values()).map((topics) => topics.length));

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        createArrayOfNumbers(0, maxRowCount - 1).map((index) => {
          return new TableRow({
            children: Object.keys(boundariesMap).map((boundary) => {
              const topic = this.topicsByBoundariesMap.get(boundary)?.[index];
              return this.createTableCell({
                text: topic?.name || topic?.code,
                styles: { shading: { fill: topTopicShadingMap[Math.floor(index / 3)] } },
              });
            }),
          });
        })
      ),
      columnWidths: Array.from({ length: 7 }, (_) => Math.floor(TABLE_WIDTH / 7)),
    });
  }

  public getTopicScoresWithDescTable() {
    const initialRows = [
      new TableRow({
        children: ['SCORE', 'TOPIC', 'DESCRIPTION'].map((label) =>
          this.createTableCell({ text: label, styles: { shading: { fill: '351c75' }, color: 'ffffff' } })
        ),
      }),
    ];

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        this.uniqueTopics.map((topic) => {
          return new TableRow({
            children: [
              this.createTableCell({ text: `${topic?.relativeScore || 0}%` }),
              this.createTableCell({ text: this.getTopicName(topic) }),
              this.createTableCell({ text: topic.description }),
            ],
          });
        })
      ),
      columnWidths: [TABLE_WIDTH * 0.1, TABLE_WIDTH * 0.2, TABLE_WIDTH * 0.7],
    });
  }

  public getTopicMappedMetricsTable() {
    const initialRows = [
      new TableRow({
        children: ['TOPIC', 'MAPPED METRIC'].map((label) =>
          this.createTableCell({ text: label, styles: { shading: { fill: '351c75' }, color: 'ffffff' } })
        ),
      }),
    ];

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        this.uniqueTopics.map((topic) => {
          return new TableRow({
            children: [
              this.createTableCell({ text: this.getTopicName(topic) }),
              this.createTableCell({ text: topic?.utrMapping?.map((utr) => this.getQuestionInfo(utr)) }),
            ],
          });
        })
      ),
      columnWidths: [TABLE_WIDTH * 0.5, TABLE_WIDTH * 0.5],
    });
  }

  public getTopicActionsTable() {
    const initialRows = [
      new TableRow({
        children: ['TOPIC', 'ACTION'].map((text) =>
          this.createTableCell({ text, styles: { shading: { fill: '351c75' }, color: 'ffffff' } })
        ),
      }),
    ];

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        this.uniqueTopics.map((topic) => {
          return new TableRow({
            children: [
              this.createTableCell({ text: this.getTopicName(topic) }),
              this.createTableCell({ text: topic.action }),
            ],
          });
        })
      ),
      columnWidths: [TABLE_WIDTH * 0.5, TABLE_WIDTH * 0.5],
    });
  }
}
