import { getGroup, Group } from '@g17eco/core';
import { SurveyModelMinimalUtrv } from '../../model/surveyData';
import { VisibilityStatus } from '../../types/download';
import { HistoricalReportData, ReportData } from '../../types/reportData';
import { Tags } from '../../types/universalTracker';
import { naturalSort } from '../../utils';
import { heading2, heading3, spacer } from './document-structure';
import { getAltName, getTypeCode } from './group-builder';
import { multiDateRenderer } from './sgx-metric-report/table/multiDateRenderer';
import { UtrvStatus } from '@constants/status';
import { ScopeGroupHistoricalData } from './types';

interface ReportDataParams {
  data: ReportData[];
  dataASC: HistoricalReportData[];
  targetMap: Map<string, SurveyModelMinimalUtrv>;
  visibilityStatus: VisibilityStatus;
  scopeGroupCode: string;
  displayUserInput?: boolean;
}

interface ReportDataBySubGroupParams extends ReportDataParams {
  subGroups: Group[];
}

export const sortData = (data: ReportData[], scopeGroupCode: string) => {
  return data.sort((a, b) => {
    return (
      naturalSort(getTypeCode(a, scopeGroupCode), getTypeCode(b, scopeGroupCode)) ||
      naturalSort(getAltName(a, scopeGroupCode), getAltName(b, scopeGroupCode))
    );
  });
};

export const filterDataBySubGroupCode = (data: ReportData[], scopeGroupCode: string, subGroupCode: string) => {
  return data.filter(
    (question) =>
      question.universalTracker.typeTags?.includes(subGroupCode) ||
      question.universalTracker.alternatives?.[scopeGroupCode]?.typeTags?.includes(subGroupCode) ||
      question.universalTracker.tags?.[scopeGroupCode as keyof Tags]?.includes(subGroupCode)
  );
};

export const generateReportData = ({
  data,
  dataASC,
  targetMap,
  visibilityStatus,
  scopeGroupCode,
  displayUserInput,
}: ReportDataParams) => {
  return data
    .map((lastSurveyUtrvs) => {
      return multiDateRenderer({
        currentData: lastSurveyUtrvs,
        questionData: dataASC,
        targetMap,
        preferredAltCodes: [scopeGroupCode],
        visibilityStatus,
        displayUserInput,
      });
    })
    .flat();
};

export const generateSubGroups = ({ subGroups, data, scopeGroupCode, ...props }: ReportDataBySubGroupParams) => {
  return subGroups
    .map((subGroup: Group) => {
      const subGroupCode = subGroup.code;
      const filteredData = filterDataBySubGroupCode(data, scopeGroupCode, subGroupCode);
      const sortedData = sortData(filteredData, scopeGroupCode);
      const reportData = generateReportData({
        data: sortedData,
        scopeGroupCode,
        ...props,
      });
      return reportData.length > 0 ? [heading3(subGroup.name || ''), ...reportData] : [];
    })
    .flat();
};

export const groupReportDataBySubgroups = (data: ReportData[], scopeGroupCode: string | undefined) => {
  if (!scopeGroupCode) {
    return {};
  }
  const subGroups = getGroup('standards-and-frameworks', scopeGroupCode)?.subgroups;

  if (!subGroups || subGroups.length === 0) {
    return {};
  }

  return subGroups.reduce((acc, subGroup) => {
    const reportDataBySubGroup = filterDataBySubGroupCode(data, scopeGroupCode, subGroup.code);
    return { ...acc, [subGroup.code]: reportDataBySubGroup };
  }, {} as { [key: string]: ReportData[] });
};

interface GenerateScopeGroupsParams {
  scopeGroupData: ScopeGroupHistoricalData[];
  targetMap?: Map<string, SurveyModelMinimalUtrv>;
  visibilityStatus: VisibilityStatus;
  displayUserInput?: boolean;
  headerText?: string;
}

export function generateScopeGroups({
  scopeGroupData,
  targetMap = new Map(),
  visibilityStatus,
  displayUserInput = false,
  headerText,
}: GenerateScopeGroupsParams) {
  // If headerText is present then downsize to heading3
  const headingFn = headerText ? heading3 : heading2;
  const scopeGroupTables = scopeGroupData
    .map((scopeGroup) => {
      // Ensure 3 max
      const historicalData = scopeGroup.questionData.slice(0, 3).map((historicalSurveyData) => {
        return {
          effectiveDate: historicalSurveyData.effectiveDate,
          unitConfig: historicalSurveyData.unitConfig,
          reportData: historicalSurveyData.reportData.filter((utrv) => {
            return utrv.status !== UtrvStatus.Created;
          }),
        };
      });

      const [latestHistorical] = historicalData;
      if (!latestHistorical) {
        return [];
      }

      const dataASC = historicalData.slice().reverse();

      // Data already filtered by type at this point, we are modifying the underlying array. Seems OK
      const scopeGroupCode = scopeGroup.code;

      const subGroups = getGroup('standards-and-frameworks', scopeGroupCode)?.subgroups || [];

      const multiDateRendererProps = {
        dataASC,
        targetMap,
        preferredAltCodes: [scopeGroupCode],
        visibilityStatus,
        displayUserInput: displayUserInput,
      };

      const data = subGroups.length
        ? generateSubGroups({
            subGroups,
            data: latestHistorical.reportData,
            scopeGroupCode,
            ...multiDateRendererProps,
          })
        : generateReportData({
            data: sortData(latestHistorical.reportData, scopeGroupCode),
            ...multiDateRendererProps,
            scopeGroupCode,
          });

      return data.length > 0 ? [headingFn(scopeGroup.name), ...data, spacer()] : [];
    })
    .flat();

  if (scopeGroupTables.length === 0) {
    return [];
  }

  return headerText ? [heading2(headerText), ...scopeGroupTables] : scopeGroupTables;
}
