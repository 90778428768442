import { Form } from './types';
import { BasicAlert } from '@g17eco/molecules';
import { SubsidiarySelectionTable } from '@components/downloads/partials/SubsidiarySelectionTable';
import { SurveySelectionTable, TableNodeWithSurveyIds } from '@components/downloads/partials/SurveySelectionTable';
import { getSelectedSurveyNodeIds } from '@components/downloads/util/treeNavigation';
import { CollapseButton, CollapseContent, CollapsePanel } from '@components/collapse-panel';

interface Props {
  initiativeId: string;
  form: Pick<Form, '_id' | 'initiativeIds' | 'surveyFilters'>;
  onChangeInitiativeIds: (initiativeIds: Form['initiativeIds']) => void;
  onChangeSurveyFilters: (surveyFilters: Form['surveyFilters']) => void;
}

export const ReportDataSource = ({ initiativeId, form, onChangeInitiativeIds, onChangeSurveyFilters }: Props) => {
  const { initiativeIds, surveyFilters } = form;

  const handleSelectedNode = (selectedNodes?: TableNodeWithSurveyIds[]) => {
    onChangeSurveyFilters(
      selectedNodes?.map(({ surveyType: type, period, effectiveDate }) => ({ type, period, effectiveDate })) ?? []
    );
  };

  return (
    <>
      <CollapsePanel className='mt-4 background-ThemeBgMedium' collapsed>
        <CollapseButton>
          <div className='pe-2 d-flex justify-content-between align-items-center'>
            <h6 className='fw-bold'>Select subsidiaries*</h6>
            <span className='text-ThemeTextMedium'>{initiativeIds.length} subsidiaries selected</span>
          </div>
        </CollapseButton>
        <CollapseContent className='p-2'>
          <SubsidiarySelectionTable
            initiativeId={initiativeId}
            selectedIds={initiativeIds}
            handleSetSelectedIds={onChangeInitiativeIds}
            hideCount
          />
        </CollapseContent>
      </CollapsePanel>
      <CollapsePanel className='mt-4 background-ThemeBgMedium' collapsed>
        <CollapseButton>
          <div className='pe-2 d-flex justify-content-between align-items-center'>
            <h6 className='fw-bold'>Date periods*</h6>
            <span className='text-ThemeTextMedium'>{surveyFilters.length} date periods selected</span>
          </div>
        </CollapseButton>
        <CollapseContent className='p-2'>
          {initiativeIds.length ? (
            <SurveySelectionTable
              isMulti
              initiativeId={initiativeId}
              initiativeIds={initiativeIds}
              handleSelectedNodes={handleSelectedNode}
              selectedNodeIds={getSelectedSurveyNodeIds(surveyFilters)}
              hideCount
            />
          ) : (
            <BasicAlert type={'warning'}>Please select subsidiaries above</BasicAlert>
          )}
        </CollapseContent>
      </CollapsePanel>
    </>
  );
};
