import { PACK, QUESTION, SURVEY } from '@constants/terminology';
import {
  Column,
  COLUMN_CODES,
  ColumnCode,
  TABULAR_COLUMN_CODES,
  TRANSPOSED_COLUMN_CODES,
} from '@g17eco/types/custom-report';
import { CustomReportTemplateType, Option } from './types';
import { OrderingDirection } from '@g17eco/types/common';

export type ColumnData = {
  code: ColumnCode;
  header: string;
  definition: string;
};

// Order by name alphabetically
const TABULAR_COLUMNS_DEFINITION: { [key in (typeof TABULAR_COLUMN_CODES)[number]]: ColumnData } = {
  input: {
    code: 'input',
    header: 'Answer',
    definition: `Value provided for the ${QUESTION.SINGULAR} and/or input`,
  },
  assuranceStatus: {
    code: 'assuranceStatus',
    header: 'Assurance status',
    definition: `Current assurance status of the ${QUESTION.SINGULAR} and/or input`,
  },
  code: {
    code: 'code',
    header: 'Code',
    definition: `Unique ${QUESTION.SINGULAR} code`,
  },
  note: {
    code: 'note',
    header: 'Further explanation / notes',
    definition: `Latest notes provided with the ${QUESTION.SINGULAR} submission`,
  },
  mapping: {
    code: 'mapping',
    header: 'Mapping',
    definition: `Alternative ${PACK.SINGULAR} mapping for ${QUESTION.SINGULAR}`,
  },

  instructions: {
    code: 'instructions',
    header: `${QUESTION.CAPITALIZED_SINGULAR} instructions`,
    definition: `${QUESTION.CAPITALIZED_SINGULAR} instructions, as seen on ${QUESTION.SINGULAR} answer page`,
  },
  valueLabel: {
    code: 'valueLabel',
    header: `${QUESTION.CAPITALIZED_SINGULAR} label`,
    definition: `${QUESTION.CAPITALIZED_SINGULAR} label, as seen on ${QUESTION.SINGULAR} answer page`,
  },
  standard: {
    code: 'standard',
    header: PACK.CAPITALIZED_SINGULAR,
    definition: `Standard or custom ${PACK.SINGULAR} where the ${QUESTION.SINGULAR} was answered`,
  },
  numberScale: {
    code: 'numberScale',
    header: 'Number scale',
    definition: 'Number scale of value (may include conversions based on overrides)',
  },
  provenance: {
    code: 'provenance',
    header: 'Provenance and evidence',
    definition: 'Link to access provenance and evidence (Assessable to users only)',
  },
  effectiveDate: {
    code: 'effectiveDate',
    header: `${SURVEY.CAPITALIZED_SINGULAR} date`,
    definition: `${SURVEY.CAPITALIZED_SINGULAR} date, as seen on ${SURVEY.SINGULAR} overview page`,
  },
  status: {
    code: 'status',
    header: 'Status',
    definition: `Current status of the ${QUESTION.SINGULAR} and/or input`,
  },
  tags: {
    code: 'tags',
    header: 'Tags',
    definition: `Tags assigned to the ${QUESTION.SINGULAR}`,
  },
  name: {
    code: 'name',
    header: 'Title',
    definition: `${QUESTION.CAPITALIZED_SINGULAR} title, as seen on ${SURVEY.SINGULAR} overview page`,
  },
  valueType: {
    code: 'valueType',
    header: 'Type',
    definition: `${QUESTION.CAPITALIZED_SINGULAR} type i.e. percentage, number, date, value list...`,
  },
  unit: {
    code: 'unit',
    header: 'Unit',
    definition: 'Unit of value (may include conversions based on override preferences)',
  },
  updatedDate: {
    code: 'updatedDate',
    header: 'Updated date',
    definition: 'Date the value was last updated',
  },
  subsidiaryCode: {
    code: 'subsidiaryCode',
    header: 'Subsidiary code',
    definition: 'System code of the subsidiary where the report data is pulled from',
  },
  subsidiaryName: {
    code: 'subsidiaryName',
    header: 'Subsidiary name',
    definition: 'Name of the subsidiary where the report data is pulled from',
  },
  subsidiaryHierarchy: {
    code: 'subsidiaryHierarchy',
    header: 'Subsidiary hierarchy',
    definition: 'Full hierarchy of subsidiary from root subsidiary',
  },
};

const COLUMNS_DEFINITION: { [key in (typeof COLUMN_CODES)[number]]: ColumnData } = {
  ...TABULAR_COLUMNS_DEFINITION,
  columnLabel: {
    code: 'columnLabel',
    header: `${QUESTION.CAPITALIZED_SINGULAR} input`,
    definition: `Input header, as seen on ${QUESTION.SINGULAR} answer page (if applicable)`,
  },
};

const TRANSPOSED_COLUMNS_DEFINITION: { [key in (typeof TRANSPOSED_COLUMN_CODES)[number]]: ColumnData } = {
  ...COLUMNS_DEFINITION,
  row: {
    code: 'row',
    header: 'Row',
    definition: `Relevant for table ${QUESTION.PLURAL} only. Signifies the table row the answer applies to.`,
  },
};

export const DEFAULT_ORDERING_DIRECTION = OrderingDirection.Asc;

export const isTransposedTemplate = (templateType: CustomReportTemplateType) =>
  templateType === CustomReportTemplateType.Transposed;

export const isTabularTemplate = (templateType: CustomReportTemplateType) =>
  templateType === CustomReportTemplateType.Tabular;

const getColumnsDefinition = (templateType: CustomReportTemplateType) => {
  if (isTransposedTemplate(templateType)) {
    return TRANSPOSED_COLUMNS_DEFINITION;
  }
  if (isTabularTemplate(templateType)) {
    return TABULAR_COLUMNS_DEFINITION;
  }
  return COLUMNS_DEFINITION;
};

export const getColumns = (templateType: CustomReportTemplateType): ColumnData[] => {
  const columnsDefinition = getColumnsDefinition(templateType);
  return Object.values(columnsDefinition).sort((a, b) => a.header.localeCompare(b.header));
};

export const getColumnsMap = (templateType: CustomReportTemplateType) => {
  const columns = getColumns(templateType);
  return Object.fromEntries(columns.map((column) => [column.code, column])) as Record<ColumnCode, ColumnData>;
};

export const getAvailableColumnCodes = (templateType: CustomReportTemplateType) => {
  const columns = getColumns(templateType);
  return columns.map((column) => column.code);
};

export const getColumnOptions = <HasCustom extends boolean = false>({
  columns,
  hasCustomOption,
  templateType,
}: {
  columns: Column[];
  hasCustomOption?: HasCustom;
  templateType: CustomReportTemplateType;
}): Option<HasCustom>[] => {
  const columnsMap = getColumnsMap(templateType);

  const options = columns.map((column) => ({
    label: columnsMap[column.code]?.header ?? '',
    value: column.code,
  }));

  if (hasCustomOption) {
    (options as Option<true>[]).push({ label: 'Custom', value: 'custom' });
  }

  return options as Option<HasCustom>[];
};

export const getDefaultDirection = (columnCode: ColumnCode) => {
  return columnCode === 'columnLabel' ? OrderingDirection.Default : DEFAULT_ORDERING_DIRECTION;
};
