import { DashboardRow, DashboardSection } from '@components/dashboard';
import {
  PPTXColorScheme,
  PPTXColorSchemeMap,
  PPTXReportScheme,
  PPTXTemplateScheme,
  PPTXTemplateSchemeLabelMap,
} from '@g17eco/types/download';
import { useState } from 'react';
import { Button } from 'reactstrap';
import { WorkInProgress } from '@g17eco/molecules';
import { ReportCard } from '../partials/ReportCard';
import classnames from 'classnames';
import { capitaliseFirstLetter } from '@utils/index';
import { generateArrayOfNumbers } from '@utils/number';

interface Props {
  isGeneratingReport: boolean;
  generateReport: (props: { scheme: PPTXReportScheme; debug?: boolean }) => void;
}

const baseImageUrl = 'https://wwg-cdn.s3.eu-west-2.amazonaws.com/pptx-report/preview';

const PPTX_SCHEMES = [
  {
    label: PPTXTemplateSchemeLabelMap[PPTXTemplateScheme.Default],
    value: PPTXTemplateScheme.Default,
    img: `${baseImageUrl}/Minimalist-Ocean-2.jpg`,
  },
  {
    label: PPTXTemplateSchemeLabelMap[PPTXTemplateScheme.Aspect],
    value: PPTXTemplateScheme.Aspect,
    img: `${baseImageUrl}/Hex-Ocean-2.jpg`,
  },
  {
    label: PPTXTemplateSchemeLabelMap[PPTXTemplateScheme.Blocks],
    value: PPTXTemplateScheme.Blocks,
    img: `${baseImageUrl}/Blocks-Ocean-2.jpg`,
  },
];

const getSchemePreviewImages = ({ template, color }: PPTXReportScheme): string[] => {
  const capitalizedColor = capitaliseFirstLetter(color);
  const imageOrders = generateArrayOfNumbers(1, 4);
  switch (template) {
    case PPTXTemplateScheme.Aspect:
      return imageOrders.map((order) => `${baseImageUrl}/Hex-${capitalizedColor}-${order}.jpg`);
    case PPTXTemplateScheme.Blocks:
      return imageOrders.map((order) => `${baseImageUrl}/Blocks-${capitalizedColor}-${order}.jpg`);
    case PPTXTemplateScheme.Default:
    default:
      return imageOrders.map((order) => `${baseImageUrl}/Minimalist-${capitalizedColor}-${order}.jpg`);
  }
};

const title = 'AI Enhanced Sustainability Reports';

const processingJobMessage = (
  <div>
    <div className='text-center text-ThemeTextMedium'>
      Your report is being generated and will appear in the ‘Latest reports’ section below in a couple of minutes.
      <br /> You can leave this page and return later. <br />
      We we send you a notification and an email when the report is ready.
    </div>
  </div>
);

const ColorScheme = ({ colors, isActive }: { colors: string[]; isActive: boolean }) => (
  <div className={classnames('d-flex gap-2 p-2 color-scheme-container', { active: isActive })}>
    {colors.map((color, idx) => (
      <div
        key={idx}
        className={classnames('color-scheme-item', {
          'border border-ThemeBorderDefault': idx === colors.length - 1,
        })}
        style={{
          backgroundColor: color,
        }}
      />
    ))}
  </div>
);

export const PPTXReportsMenu = (props: Props) => {
  const { isGeneratingReport, generateReport } = props;
  const [scheme, setScheme] = useState<PPTXReportScheme>({
    template: PPTXTemplateScheme.Default,
    color: PPTXColorScheme.Ocean,
  });

  const handleUpdateScheme = (key: keyof PPTXReportScheme, value: PPTXTemplateScheme | PPTXColorScheme) => {
    setScheme((prev) => ({ ...prev, [key]: value }));
  };

  if (isGeneratingReport) {
    return (
      <DashboardRow title={title}>
        <WorkInProgress title={processingJobMessage} />
      </DashboardRow>
    );
  }

  const images = getSchemePreviewImages(scheme);

  return (
    <>
      <DashboardRow title={title}>
        <div className='d-flex gap-2 mx-auto mt-4 mb-2'>
          <Button className='px-4 py-2 text-lg fw-semibold' color='primary' onClick={() => generateReport({ scheme })}>
            <i className='fal fa-file mr-1 text-xl' /> Generate Report
          </Button>
          <Button
            className='px-4 py-2 text-lg'
            color='primary'
            outline
            onClick={() => generateReport({ scheme, debug: true })}
          >
            <i className='fal fa-file-powerpoint mr-1 text-xl' /> DEBUG
          </Button>
        </div>
      </DashboardRow>
      <DashboardSection title='Selected PowerPoint report theme' padding={0}>
        <div className='d-flex align-items-center justify-content-between gap-5 scheme-container'>
          <div className='d-flex justify-content-center gap-4 mb-3'>
            {PPTX_SCHEMES.map(({ label, value, img }, idx) => (
              <ReportCard
                key={idx}
                text={label}
                img={img}
                onClick={() => handleUpdateScheme('template', value)}
                active={value === scheme.template}
              />
            ))}
          </div>
          <div>
            {Object.entries(PPTXColorSchemeMap).map(([colorScheme, colors]) => {
              return (
                <div
                  key={colorScheme}
                  className='d-flex align-items-center gap-3 cursor-pointer'
                  onClick={() => handleUpdateScheme('color', colorScheme as PPTXColorScheme)}
                >
                  <ColorScheme colors={colors} isActive={colorScheme === scheme.color} />
                  <span className='fw-semibold'>{colorScheme.toUpperCase()}</span>
                </div>
              );
            })}
          </div>
        </div>
      </DashboardSection>
      <DashboardRow className='py-2'>
        <div className='d-flex justify-content-center w-100 gap-2'>
          {images.map((url) => (
            <img key={url} src={url} width={210} />
          ))}
        </div>
      </DashboardRow>
    </>
  );
};
