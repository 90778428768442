import { DashboardRow } from '@components/dashboard';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { CurrentUserData } from '@reducers/current-user';
import { AssuranceListPortfolio } from '@g17eco/types/assurance';
import { AssuranceAction, hasPortfolioPermission } from './permissions-utils';
import { getFullName } from '@utils/user';
import { ManagementUser } from '@constants/users';

export enum ReportOption {
  ExcludeDeleted = 'exclude-deleted-report',
  IncludeDeleted = 'include-deleted-report',
}

export enum StatusOption {
  All = 'all',
  Assured = 'assured',
  InProgress = 'in-progress',
}

const reportSelectOptions = [
  { label: 'Exclude deleted reports', value: ReportOption.ExcludeDeleted },
  { label: 'Include deleted reports', value: ReportOption.IncludeDeleted },
];

const statusSelectOptions = [
  { label: 'All report statuses', value: StatusOption.All },
  { label: 'Assured reports only', value: StatusOption.Assured },
  { label: 'Assurance in progress', value: StatusOption.InProgress },
];

export interface Filters {
  delegation: string[] | undefined;
  report: ReportOption;
  status: StatusOption;
}

interface Props {
  filters: Filters;
  handleSetFilters: (props: Partial<Filters>) => void;
  users: ManagementUser[];
  currentUser: CurrentUserData;
  assuranceList: AssuranceListPortfolio[] | undefined;
}

export const AssuranceListFilters = (props: Props) => {
  const { filters, handleSetFilters } = props;

  return (
    <DashboardRow>
      <div className='w-100 d-flex gap-2 flex-column flex-md-row'>
        <DelegationFilter {...props} />
        <SelectFactory<ReportOption>
          className='flex-grow-1'
          selectType={SelectTypes.SingleSelect}
          options={reportSelectOptions}
          value={reportSelectOptions.find((op) => op.value === filters.report) ?? null}
          onChange={(op) => (op ? handleSetFilters({ report: op.value }) : undefined)}
          isSearchable={false}
        />
        <SelectFactory<StatusOption>
          className='flex-grow-1'
          selectType={SelectTypes.SingleSelect}
          options={statusSelectOptions}
          value={statusSelectOptions.find((op) => op.value === filters.status) ?? null}
          onChange={(op) => (op ? handleSetFilters({ status: op.value }) : undefined)}
          isSearchable={false}
        />
      </div>
    </DashboardRow>
  );
};

const DelegationFilter = (props: Props) => {
  const { filters, handleSetFilters, currentUser, users, assuranceList } = props;

  const options = [{ label: 'Assigned to me', value: currentUser._id }];
  if (assuranceList) {
    users.forEach((user) => {
      if (user._id === currentUser._id) {
        return;
      }
      const isDelegatedToAny = assuranceList.some((assurancePortfolio) =>
        hasPortfolioPermission({ user, action: AssuranceAction.CanAssureMetrics, assurancePortfolio })
      );
      if (isDelegatedToAny) {
        options.push({ label: getFullName(user), value: user._id });
      }
    });
  }

  return (
    <SelectFactory<string>
      className='flex-grow-1'
      selectType={SelectTypes.MultipleSelect}
      placeholder={'Delegation'}
      options={options}
      values={filters.delegation ?? []}
      onChange={(values) => handleSetFilters({ delegation: values.length > 0 ? values : undefined })}
    />
  );
};
