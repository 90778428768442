import { Input, Form as FormWrapper, FormGroup, Label, Col } from 'reactstrap';
import { CustomReportTemplateType, Form, OnChangeForm } from './types';
import { ReportDataSource } from './ReportDataSource';
import { Filters } from './Filters';
import { getScopesDifferent, getScopeModules, mergeSurveysScopes } from '@utils/survey-scope';
import { TemplateType } from './TemplateType';
import { isTabularTemplate, isTransposedTemplate } from './utils';
import {
  useGetMetricGroupsForCustomReportQuery,
  useGetSubsidiariesForCustomReportQuery,
  useGetSurveysByInitiativeIdsForCustomReportQuery,
} from '@api/custom-reports';
import { SurveyFilter } from '@g17eco/types/custom-report';

interface Props {
  initiativeId: string;
  form: Form;
  onChangeForm: OnChangeForm;
}

const filterBySurveyFilters = <T extends SurveyFilter>(toBeFilteredSurveys: T[], surveyFilters: SurveyFilter[]) =>
  toBeFilteredSurveys.filter((survey) =>
    surveyFilters.some(
      (filter) =>
        filter.period === survey.period && filter.type === survey.type && filter.effectiveDate === survey.effectiveDate
    )
  );

export const Configuration = ({ initiativeId, form, onChangeForm }: Props) => {
  const { name, description, metricFilters, columns, surveyFilters } = form;

  const { data: subsidiaryList } = useGetSubsidiariesForCustomReportQuery(initiativeId, {
    skip: !initiativeId,
  });

  const { data: surveys = [] } = useGetSurveysByInitiativeIdsForCustomReportQuery(
    {
      initiativeId,
      initiativeIds: subsidiaryList?.map(({ _id }) => _id) ?? [],
    },
    { skip: !initiativeId || !subsidiaryList?.length }
  );

  const { data: metricGroups = [] } = useGetMetricGroupsForCustomReportQuery(initiativeId);

  const getScope = (surveyFilters: Form['surveyFilters']) => {
    const filteredSurveys = filterBySurveyFilters(surveys, surveyFilters);

    return mergeSurveysScopes(filteredSurveys, { parentGroupsOnly: true });
  };

  const originalScope = getScope(surveyFilters);

  const getNewMetricFilters = (newSurveyFilters: Form['surveyFilters']) => {
    const unSelectedScope = getScopesDifferent(metricFilters.scope, originalScope);
    return {
      ...metricFilters,
      scope: getScopesDifferent(unSelectedScope, getScope(newSurveyFilters)),
    };
  };

  const handleChangeSurveyFilters = (newSurveyFilters: Form['surveyFilters']) => {
    onChangeForm({
      surveyFilters: newSurveyFilters,
      metricFilters: getNewMetricFilters(newSurveyFilters),
    });
  };

  const handleChangeInitiativeIds = (initiativeIds: string[]) => {
    const filteredSurveys = surveys.filter(({ initiativeId }) => initiativeIds.includes(initiativeId));
    const newSurveyFilters = filterBySurveyFilters(surveyFilters, filteredSurveys);
    onChangeForm({
      initiativeIds,
      surveyFilters: newSurveyFilters,
      metricFilters: getNewMetricFilters(newSurveyFilters),
    });
  };

  const getUpdatedColumns = (templateType: CustomReportTemplateType) => {
    if (isTransposedTemplate(templateType)) {
      return columns;
    }

    const excludedColumns = isTabularTemplate(templateType) ? ['row', 'columnLabel'] : ['row'];
    return columns.filter((col) => !excludedColumns.includes(col.code));
  };

  const handleChangeTemplateType = (templateType: CustomReportTemplateType) => {
    onChangeForm({ templateType, columns: getUpdatedColumns(templateType) });
  };

  return (
    <FormWrapper>
      <FormGroup row>
        <Label for='reportName' sm={2}>
          <h6 className='text-ThemeHeadingDark'>Report name*</h6>
        </Label>
        <Col sm={10}>
          <Input
            id='reportName'
            type='text'
            placeholder='Enter report name...'
            value={name}
            onChange={(e) => onChangeForm({ name: e.target.value })}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='reportDescription' sm={2}>
          Description
        </Label>
        <Col sm={10}>
          <Input
            id='reportDescription'
            type='textarea'
            placeholder='Enter report description (optional)'
            value={description}
            onChange={(e) => onChangeForm({ description: e.target.value })}
          />
        </Col>
      </FormGroup>
      <TemplateType form={form} onChangeTemplateType={handleChangeTemplateType} />
      <ReportDataSource
        initiativeId={initiativeId}
        form={form}
        onChangeInitiativeIds={handleChangeInitiativeIds}
        onChangeSurveyFilters={handleChangeSurveyFilters}
      />
      <Filters
        filters={metricFilters}
        onChangeForm={onChangeForm}
        scopeModules={getScopeModules({ scope: originalScope, metricGroups })}
      />
    </FormWrapper>
  );
};
