import { AssessmentData } from '@apps/materiality-tracker/api/materiality-assessment';
import { boundaryMap, esgMap, materialPillarMap } from '@apps/materiality-tracker/utils';
import { GlossaryText } from '@components/glossary-text/GlossaryText';
import { ExpandableParagraph } from '@g17eco/molecules';
import { useAppSelector } from '@reducers/index';
import { Col, Row } from 'reactstrap';

const Detail = ({ label, icon, color }: { label: string; icon?: string; color?: string }) => {
  return (
    <div className='d-flex align-items-center' style={{ height: '28px' }}>
      {icon ? <i className={`fa-fw fal ${icon} ${color ?? 'text-ThemeIconDark'} mr-2 text-xl`} /> : null}
      {label}
    </div>
  );
};

export const MaterialTopicDetails = ({ data, priority }: { data: AssessmentData; priority: number }) => {
  const { relativeScore, score, categories = {}, description, action } = data;
  const { esg = [], materialPillar = [], boundary = [] } = categories;

  const glossaryState = useAppSelector((state) => state.glossary.data);

  return (
    <div className='background-ThemeBgMedium p-3 mt-3 rounded'>
      <h4 className='text-ThemeTextDark'>{data.name}</h4>
      <Row className='mt-3'>
        <Col>
          <h6 className='fw-bold'>Material relevance</h6>
          {[`Priority: #${priority}`, `Relevance: ${relativeScore ?? ''}`, `Score: ${score}`].map((label, idx) => (
            <Detail key={idx} label={label} />
          ))}
        </Col>
        <Col>
          <h6 className='fw-bold'>Boundaries</h6>
          {boundary.map((code) => (
            <Detail key={code} {...boundaryMap[code]} />
          ))}
        </Col>
        <Col>
          <h6 className='fw-bold'>ESG relevance</h6>
          {esg.map((code) => (
            <Detail key={code} {...esgMap[code]} />
          ))}
        </Col>
        <Col>
          <h6 className='fw-bold'>Pillar</h6>
          {materialPillar.map((code) => (
            <Detail key={code} {...materialPillarMap[code]} />
          ))}
        </Col>
      </Row>
      {description ? (
        <>
          <h6 className='fw-bold mt-3'>Description</h6>
          <ExpandableParagraph defaultHeight={44} key={`description-${data.code}`}>
            {description}
          </ExpandableParagraph>
        </>
      ) : null}
      {action ? (
        <>
          <h6 className='fw-bold mt-3'>Action</h6>
          <ExpandableParagraph defaultHeight={86} key={`action-${data.code}`}>
            <GlossaryText text={action} glossary={glossaryState} />
          </ExpandableParagraph>
        </>
      ) : null}
    </div>
  );
};
